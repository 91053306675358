import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Features = (props,{className, ...rest }) => {
  const classes = useStyles();
  const [content, setContent] = useState([{
       Name:"",
       Access:true
    }]); 
  const [park, setPark] = useState(false); 
  const [verify, setVerify] = useState(false); 
  useEffect(() => {
    setContent(props.content); 
    props.content.map((element,index) => {
       if(element.Name=='Park'){
        setPark(element.Access);
       }
       if(element.Name=='Verify'){
        setVerify(element.Access);
      }
   
       }) 
   }, [props]);
   const handleChangePark = (event) => {
     setPark(event.target.checked);
  }; 
  const handleChangeVerify = (event) => {
     setVerify(event.target.checked);
 }; 
    
  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Manage Permission"
          title="Features"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={12}
              sm={12}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Permissions
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox checked={verify} onChange={handleChangeVerify} />
                )}
                label="Verify Invoice"
              />
              <FormControlLabel
                control={(
                  <Checkbox checked={park} onChange={handleChangePark} />
                )}
                label="Park Invoice"
              />
               
            </Grid>
            </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={              
              props.handleFeatures.bind(null,verify,park)
            }            
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Features.propTypes = {
  className: PropTypes.string,
  handleFeatures:PropTypes.func
};

export default Features;
