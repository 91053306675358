import React from "react"; 
//import "./placeholder.css"; 
import clsx from 'clsx'; 
import {
    Card,
    CardContent,
    MLink,
    Typography,
    CardActionArea,
    Grid,
    CardMedia,
    makeStyles,
    createStyles,
    CardActions,
    Avatar, 
    colors

} from "@material-ui/core"; 
import MaterialTable from "material-table";
import { Skeleton } from '@material-ui/lab'; 
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
        height: '100%'
      },
    avatar: { 
        height: 56,
        width: 56
      },
    details:{
        flex: "1 0 auto"
    }
    
  }))

 

export const TilesSkelton = (props) => {
    
    const classes = useStyles();
  return (
    <Grid  item  lg={3}
    sm={6}
    xl={3}
    xs={12}>
    <Card

    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid className={classes.details} item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
                <Skeleton mb={2}  variant="rect" height={30}/>
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
               <Skeleton mb={2}  variant="rect" width={80} height={30}/>
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
            <Skeleton  variant="circle" width = {56} height={56}/>
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
     
    </Grid>
  )
  }