import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { useSnackbar } from 'notistack';
import Spinner from 'src/common/Spinner';

Amplify.configure(awsconfig);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const GSTINRegExp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/


  const [isLoading, setIsLoading] = useState(false);
  const [isExitsGSTIN, setExitsGSTIN] = useState();

  async function signUp(e) {
    /*    e.preventDefault();  */
    var username = e.email;
    var password = e.password;
    var attrVal = {
      email: e.email,
      name: e.firstName + ' ' + e.lastName,
      "custom:firstname": e.firstName,
      "custom:lastname": e.lastName,
      "custom:tenantid": e.email,
      "custom:accounttype": "MASTER",
      "phone_number": "+91" + e.phone,
    };
    Auth.signUp({
      username,
      password,
      attributes: attrVal
    })
      .then(response => {
        console.log("response" + JSON.stringify(response));
        if (response.UserSub != "") {
          console.log("Successful userSub" + response.UserSub);
          console.log("Successful CodeDeliveryDetails" + response.CodeDeliveryDetails);
          updatepropertyDB(response, e);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  };

  async function updatepropertyDB(res, e) {
    const BusinessPlaces = [{
      "pk": e.gstin, "gstin": e.gstin, "primary": "yes", "status": "active", "AccNum":e.AccNum,
      "BankName": e.BankName, "BankBranch": e.BankBranch, "ifsc": e.ifsc, "vpa": e.vpa, "type": "bankdetails",
      "tennantId": e.email
    }];
   // const BusinessPlaces = [{ "gstin": e.gstin, "primary": "yes", "status": "active" }];
    console.log("UPDATE PROPERTY TO DB");
    let apiName = "OptiVIVeAPI";
    let path = '/b2cstore/updateUserProperty';
    var body = {};
    body.pk = res.userSub;
    body.email = e.email;
    body.name = e.firstName + ' ' + e.lastName;
    body["custom:firstname"] = e.firstName;
    body["custom:lastname"] = e.lastName;
    body["custom:accounttype"] = "MASTER";
    body["custom:tenantid"] = e.email;
    body["PhoneNumber"] = e.phone;
    body["gstin"] = e.gstin;
    body["BusinessPlaces"] = BusinessPlaces;
    body["company_name"] = e.company;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      setIsLoading(false);
      enqueueSnackbar("User " + res.user.username + " Registered successfully.. !!  Please confirm your email id before login.",
        {
          variant: 'success',
        });
      /* handleClearForm(); */
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err,
        {
          variant: 'error',
        });
      //openSnackbar({ message: err, varient: "error" });
    });
  }
  async function CheckingGSTIN(value) {

    console.log("Get GST No");
    let apiName = "OptiVIVeAPI";
    let path = '/b2cstore/AvailableGSTIN';
    var body = {};
    body.gstin = value;

    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("Responce Data", response);
      setExitsGSTIN(response.value);


    }).catch(err => {
      console.log(err);

    });


  }

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Spinner left={70} top={0} isLoading={isLoading} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              phone: '',
              gstin: '',
              AccNum: '',
              BankName: '',
              BankBranch:'',
              ifsc: '',
              vpa: '',
              company: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                firstName: Yup.string().max(255).required('First name is required'),
                lastName: Yup.string().max(255).required('Last name is required'),
                password: Yup.string().max(255).required('password is required'),
                phone: Yup.string().required("Phone number required").matches(phoneRegExp, 'Phone number is not valid')
                  .min(10, "to short")
                  .max(10, "to long"),
                AccNum: Yup.string().max(255).required('Account number is required'),
                BankName: Yup.string().max(255).required('Bank Name is required'),
                ifsc: Yup.string().max(255).required('IFSC is required'),
                vpa: Yup.string().max(255).required('VPA is required'),
                gstin: Yup.string().required("GSTIN is required").matches(GSTINRegExp, 'GSTIN is not valid').test('Unique GSTIN', 'GSTIN already in use',
                  function (value) {
                    return new Promise((resolve, reject) => {
                      if (value && value !== "") CheckingGSTIN(value);
                      isExitsGSTIN == 'NotAvailable' ? resolve(false) : resolve(true)
                    })
                  }
                ),
                company: Yup.string().max(255).required('Company name is required'),
                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={async (values, { resetForm }) => {
              await signUp(values);
              resetForm({ values: '' })
              console.log("(JSON.stringify(values, null, 2)", (JSON.stringify(values, null, 2)));
              /* navigate('/app/dashboard', { replace: true }); */
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create a new Account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Phone Number"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="phone"
                  value={values.phone}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.gstin && errors.gstin)}
                  fullWidth
                  helperText={touched.gstin && errors.gstin}
                  label="GSTIN"
                  margin="normal"
                  name="gstin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gstin}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.company && errors.company)}
                  fullWidth
                  helperText={touched.company && errors.company}
                  label="Company name"
                  margin="normal"
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.AccNum && errors.AccNum)}
                  fullWidth
                  helperText={touched.AccNum && errors.AccNum}
                  label="Account Number"
                  margin="normal"
                  name="AccNum"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.AccNum}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.BankName && errors.BankName)}
                  fullWidth
                  helperText={touched.BankName && errors.BankName}
                  label="Bank Name"
                  margin="normal"
                  name="BankName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BankName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.BankBranch && errors.BankBranch)}
                  fullWidth
                  helperText={touched.BankBranch && errors.BankBranch}
                  label="Bank Branch"
                  margin="normal"
                  name="BankBranch"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BankBranch}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.ifsc && errors.ifsc)}
                  fullWidth
                  helperText={touched.ifsc && errors.ifsc}
                  label="IFSC"
                  margin="normal"
                  name="ifsc"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ifsc}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.vpa && errors.vpa)}
                  fullWidth
                  helperText={touched.vpa && errors.vpa}
                  label="VPA"
                  margin="normal"
                  name="vpa"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vpa}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
