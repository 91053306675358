import React, { useState,useEffect } from 'react';
import {CircularProgress,makeStyles,Backdrop } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {}, 
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }));
export default function Spinner(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false); 
  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props]); 
    return ( 
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress   color="inherit" size={50} />
    </Backdrop>
    
    )
}
