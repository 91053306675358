import React,{useState} from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Page from 'src/components/Page';
import  {API } from 'aws-amplify';
import DataFetcher from 'src/utils/Fetcher';
import Spinner from 'src/common/Spinner';
import { useSnackbar } from 'notistack';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    justify: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  header: {
    fontSize: 20,
    fontStyle: "bold",
    fontFamily: "Arial-BoldMT, Arial"
  },
  myImage: {
    width: "100%"
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
  welcomeText: {
    fontSize: "20px",
  }
}));


const VerifyView = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  var vrData = {}
/*   const queryParams = new URLSearchParams(props.location.search);  
  queryParams.forEach(function (value, key) {
    console.log('assuming query param is ' + key, value);
    vrData[key] = value;
  }); */
  const [verifyData, setVerifyData] = useState({});
  var [isVerifyLoading, setIsVerifyLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [welcomeView, setWelcomeView] = useState(false);
  const [confirmPass, setConfirmPass] = useState({
    newPassword: "",
    email: "",
    password: "",
    codeSent: false,
    confirmed: false,
    confirmPassword: "",
    isConfirming: false,
    isSendingCode: false,
    isLoading: false,
    setIsLoading: (value) => {
      confirmPass.isLoading = value;
    },
    showPassword: false
  });
  React.useEffect(() => {
     console.log("searchParams",searchParams);
      /* const queryParams = new URLSearchParams(props.location.search); */
      searchParams.forEach(function (value, key) {
          console.log('assuming query param is ' + key, value);
          vrData[key] = value;
      });
      setVerifyData(vrData);
  }, [searchParams]);
  const handleClickShowPassword = () => {
    console.log("Handle show ", confirmPass.showPassword);
    setConfirmPass({ ...confirmPass, showPassword: !confirmPass.showPassword });
    console.log("Handle show ", confirmPass.showPassword);
  };
  const handleMouseDownPassword = e => {
    e.preventDefault();
  };
  
  const verifyUser=async props =>{
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/verify/verify";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: props.userData 
      };
      API.post(apiName, path, myInit).then(response => {        
        console.log("user response",response);              
        resolve(response);
      }).catch(err=>{console.log(err); reject(err);
      });
    });
   }
  const confirmPassword = async (e) => {
    console.log("FORGOT PASSWORD ", confirmPass);
    confirmPass.setIsLoading(true);
    setIsVerifyLoading(true); 
    /* e.preventDefault(); */
    setConfirmPass({ ...confirmPass, isConfirming: true });
    let apiName = "OptiVIVeAPI";
    let path = "/b2cstore /reset";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {
        email: verifyData.email,
        password: e.newPassword,
        code: verifyData.code,
        userId: verifyData.username        
      },
      queryStringParameters: {
        module: "user",
        op: "forceResetPassword"
      }
    };
    API.post(apiName, path, myInit).then(response => {
      console.log("user response", response);
      setIsVerifyLoading(false);
      if (response.success) {
        console.log("response" + JSON.stringify(response));
        confirmPass.setIsLoading(false);
        setConfirmPass({ ...confirmPass, confirmed: true, password: "", confirmPassword: "", newPassword: "" }); 
        enqueueSnackbar(response.message,{ 
          variant: 'success',
        }); 
        setWelcomeView(true);
      }
      else { 
        enqueueSnackbar(response.message,{ 
          variant: 'error',
        });
        confirmPass.setIsLoading(false);
        setConfirmPass({ ...confirmPass, isConfirming: false });
        setWelcomeView(true);
      }
    }).catch(err => {
      console.log(err); 
      enqueueSnackbar(err.message,{ 
        variant: 'error',
      });
      confirmPass.setIsLoading(false);
      setConfirmPass({ ...confirmPass, isConfirming: false });
    });
  }
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {(verifyData.type && verifyData.type === "reset") ?
        <Container maxWidth="sm">
        {welcomeView ? <>
                <Grid container justify="flex-start" direction="column" alignItems="flex-start" spacing={2}> 
                  <Grid item>
                    <Typography    color="textPrimary"
                    variant="h2">
                      Hello {verifyData.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography     color="textSecondary"
                    gutterBottom
                    variant="body2">
                      Password set successfully
                  </Typography>
                  </Grid>
                  <Grid item>
                    <Typography   color="textSecondary"
                    gutterBottom
                    variant="body2">
                      Please countinue by clicking the login button below.
                  </Typography>
                  </Grid>
                  <Grid item>
                  <Button
                    color="primary" 
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    block="true"
                    href="/login"
                  >
                    LOGIN
                  </Button> 
                  </Grid>
                
                </Grid>

              </> 
        :<>
        <Grid container justify="center" alignItems="center" spacing={1}> 
                    <Grid item>
                    <Typography
                    align="center"
                    color="textSecondary"
                    variant="h1"
                  >
                        Welcome {verifyData.name}! New Registration
              </Typography>
                    </Grid>
                  </Grid> 
          <Formik
            initialValues={{
              email: '',
              newPassword: ''
            }}
            validationSchema={Yup.object().shape({
              newPassword:Yup.string().max(255).required('Password is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values) => {
                await confirmPassword(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>  
                <TextField
                          error={Boolean(touched.newPassword && errors.newPassword)}
                          autoComplete="new password"
                          helperText={touched.newPassword && errors.newPassword}
                          name="newPassword"
                          variant="outlined"
                          required 
                          fullWidth
                          margin="normal"
                          type="password"
                          id="newPassword"
                          label="NEW PASSWORD"
                          value={values.newPassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          autoFocus
                        />
                  <FormControl fullWidth required className={clsx(classes.margin)} variant="outlined">
                      <InputLabel htmlFor="password">VERIFY NEW PASSWORD</InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.password && errors.password)} 
                        helperText={touched.password && errors.password}
                        id="password" 
                        type={confirmPass.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onBlur={handleBlur}
                        margin="normal"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword()}
                              onMouseDown={(e) => handleMouseDownPassword(e)}
                            >
                              {confirmPass.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={200}
                      />
                    </FormControl>
              {/*   <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                /> */}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                   Set New Password
                  </Button>
                </Box> 
              </form>
            )}
          </Formik>
          </>}
        </Container>
      : (verifyData.code && verifyData.code !== "") ?  <DataFetcher type="verify" userData={verifyData}>
      {({ data, IsLoading, error }) => {
        console.log("Verify data", data);
        console.log("Verify isLoading", IsLoading);
        console.log("Verify error", error);
        if (!data) {
          return (
            <Spinner left={70} top={0}  isLoading={true} />
          );
        }
        if (error) {
          return <p>error.message</p>;
        }
        if (IsLoading) {
          return (
            <Spinner left={70} top={0}  isLoading={true} />
          );
        }
        return (data &&
          <div width="100%">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '85vh' }}
            >
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Container component="main" > 
                  <div className={classes.paper}>
                    <Grid container justify="flex-start" direction="column" alignItems="flex-start" spacing={2}>
                      <Grid item>
                          <Typography className='managment-header'>
                            {"OoPTIeiNVOICE b2C"}
                          </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="verify-user-header" gutterBottom={true}>
                          Hello {verifyData.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h1" variant="h5">
                          {data.message}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h1" variant="h5">
                          Please countinue by clicking the login button below.
              </Typography>
                      </Grid>
                      <Grid>
                      <Button
                    color="primary" 
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={()=>{ navigate('/login')}}
                  >
                   LOGIN
                  </Button>
                      </Grid>
                    </Grid>

                  </div>
                </Container>
              </Grid>
            </Grid> 
          </div>
        );
      }}
    </DataFetcher>: <Spinner left={70} top={0}  isLoading={true} />}
      </Box>
    </Page>
  );
};

export default VerifyView;
