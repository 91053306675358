import React, { useState } from 'react';
import { Outlet,useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Amplify, { Auth,API } from 'aws-amplify';
import awsconfig from 'src/aws-exports'; 
import { useSnackbar } from 'notistack';

Amplify.configure(awsconfig);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = (props) => { 
  const classes = useStyles();
  const navigate = useNavigate();  
  const { enqueueSnackbar } = useSnackbar();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  
  console.log("DashboardLayout",props);
   
  async function handleLogout() { 
    delete localStorage.email;
    await Auth.signOut();
    props.userHasAuthenticated(false);
    console.log("MenuAppBar props 2", props);
    navigate("/");
  }
  
  async function handleNotification() { 
    console.log("Hanle event");
     enqueueSnackbar('This is a success message!',{ 
      variant: 'success',
  });
    //openSnackbar({ message: "Error happened", varient: "error" });
   
  }
  return (
    <div className={classes.root}>
       
      <TopBar handleNotification={()=>{handleNotification()}}  handleLogout={()=>{handleLogout()}} onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar 
        /* userSession={props.userSession} */
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
