import React,{useState,useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core'; 
import EmailIcon from '@material-ui/icons/Email';
import FolderIcon from '@material-ui/icons/Folder';
import HttpIcon from '@material-ui/icons/Http';
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const TrafficBySource = (props,{ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false); 
  const [traffic, setTraffic] = usePersistedState('traffic',{
    "Email":0,
    "Api":0,
    "Sftp":0,
    "total":0
  }); 
  useEffect(() => {
    const getdashboardMatrix = async () => {
      setIsLoading(true);
      try {
       let apiName = "OptiVIVeAPI";
       let path = "/b2c ";
       let myInit = {
         headers: { "Content-Type": "application/json" },
         body: { masterType: "User", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId  },
         queryStringParameters: {
           module: "invoice",
           op: "getsourceTrafic"
         }
       };
        var response=await API.post(apiName, path, myInit);
        await setTraffic(response.data);
        await setIsLoading(false);
     }
     catch (error) {
       console.log("set login time error",error); 
     } 
   } 
   getdashboardMatrix()
   },[]);

  const data = {
    datasets: [
      {
        data: [traffic.Email,traffic.Sftp,traffic.Api],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['EMAIL', 'SFTP', 'API']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'EMAIL',
      value: traffic.Email,
      icon: EmailIcon,
      color: colors.indigo[500]
    },
    {
      title: 'SFTP',
      value: traffic.Sftp,
      icon: FolderIcon,
      color: colors.red[600]
    },
    {
      title: 'API',
      value: traffic.Api,
      icon: HttpIcon,
      color: colors.orange[600]
    }
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Traffic" />
      <Divider />
      <CardContent>
        <Box
          height={210}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

TrafficBySource.propTypes = {
  className: PropTypes.string
};

export default TrafficBySource;
