import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types'; 
import {Tabs,Tab,AppBar} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import ProfileDedicatedmail from './ProfileDedicatedmail';
import Spinner from 'src/common/Spinner';
import Amplify, { Auth, API ,Storage} from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import Password from './Password';
import { useSnackbar } from 'notistack';
 Amplify.configure(awsconfig);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  fabSpinner: {     
    display: 'inline-block',
    position: 'relative',     
    marginLeft: '50%'
     
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const Account = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false); 
  const [dedicatedEmail,setDedicatedEmail]=useState("");
  const [childParameters,setchildParameters]=useState("");
  const handleChange = (event, newValue) => {     
    setValue(newValue);    
  };
  console.log('Account props',props.userSession);
  window.$Email=userDetails.ConfiguredEmail && userDetails.ConfiguredEmail!=''?true:false;
   useEffect(() => {    
   getUser()
   },[]);
   const getUser = async () => {
    setIsLoading(true);
    try {
     let apiName = "OptiVIVeAPI";
     let path = "/b2c ";
     let myInit = {
       headers: { "Content-Type": "application/json" },
       body: { masterType: "User", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId  },
       queryStringParameters: {
         module: "user",
         op: "getUserProfile"
       }
     };
      var response=await API.post(apiName, path, myInit);
      await setUserDetails(response.data);  
      await setDedicatedEmail(response.DedicateEmail);   
      await setIsLoading(false);
      console.log(userDetails);
   }
   catch (error) {
     console.log("set login time error",error); 
   } 
 } 
 const handleConfigureMail = (f, evt, key, payload) => { 
      //console.log(f.ConfiguredEmail +"@" + dedicatedEmail);
      updatepropertyDB(f.ConfiguredEmail+"@" + dedicatedEmail);
     
  }
  async function updatepropertyDB(configureMID) {

    console.log("UPDATE PROPERTY TO DB");
    let apiName = "OptiVIVeAPI";
    let path = "/b2c ";
    var body = {};
    body.masterType = "User";
    body.userData = props.userSession;
    body.ConfiguredEmail = configureMID;
    body.tenantId=props.userSession.TenantId 
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "user",
        op: "setConfiguredEmail"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      //setIsLoading(false);
      setchildParameters(1);
      getUser();
      enqueueSnackbar("Dedicated Mail " + configureMID + " Registered successfully.. !! .",{ 
        variant: 'success',
      });

    }).catch(err => {
      console.log(err);
      //setIsLoading(false); 
      setchildParameters(1);
      enqueueSnackbar(err,{ 
        variant: 'error',
      });
    });
  }
 
const onChange = (e, evt, key, payload) => {
  if (e.target.name === "profilePicture") {
    const target = e.target;
    const file = target.files[0];
    let targetName = target.name;
    var img = document.createElement("img");    
    
    img.onload = function () {
      if (targetName === "profilePicture" && this.width <= 500 && this.height <= 500 ) {
        if(userDetails.profilePicture){
          delteFileFromS3(userDetails.profilePicture)
        }
        let fileName = file.name;
        let fileExt = getFileExtension(fileName);
        let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
        const fileData = s3Key;      
       Storage.put(s3Key, file, {
          contentType: file.type
        })
          .then(result => {
            setUserDetails({ ...userDetails, [targetName]: fileData });
            console.log('userDetails::',userDetails);
            setProfilePictureDB(fileData); 
          })
          .catch(err => console.log(err));
      }
      else {
        (targetName === "profilePicture") ? enqueueSnackbar("Image size should be samaller than 1000X500 pixels.",{variant: 'error'}) : enqueueSnackbar("Image size should be lesser than 500X500",{variant: 'error'});
      }
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(target.files[0]);
  }
  else {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  }
 
};
function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}
const delteFileFromS3 = async (element) => {
   
    await Storage.remove(element)
      .then(result => { console.log(result); })
      .catch(err => console.log(err));
   
}
 
async function setProfilePictureDB(fileData) {

  console.log("UPDATE PROPERTY TO DB");
  let apiName = "OptiVIVeAPI";
  let path = "/b2c ";     
  var body = {}; 
  body.masterType = "User";
  body.userData = props.userSession;
  body.profilePicture =fileData;     
  body.tenantId=props.userSession.TenantId 
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body: body,   
    queryStringParameters: {
      module: "user",
      op: "setprofilePicture"
    }
  };
  console.log(myInit);

  API.post(apiName, path, myInit).then(response => {
    console.log("UPDATE USER PROPERTY", response);     
    if(response.success)
    {
      getUser();
      enqueueSnackbar("Profile Picture Updated successfully.. !! .",{ 
        variant: 'success',
      });      
    }
    else
    {
      enqueueSnackbar("Something went wrong. Try after sometime!!",{ 
        variant: 'error',
      }); 
    }
  }).catch(err => {
    console.log(err);
     
    
  });
}
  return (
  
    <Page
      className={classes.root}
      title="Account"
    ><div>    
    <AppBar position="static" style={{ background: 'white' }}>
    <Tabs value={value} onChange={handleChange} aria-label="Profile tabs">
        <Tab style={{ color: '#2E3B55' }} label="Profile" {...a11yProps(0)} />
         <Tab style={{ color: '#2E3B55' }} label="Plan & Usage" {...a11yProps(1)} />
        <Tab style={{ color: '#2E3B55' }} label="UPDATE PASSWORD" {...a11yProps(2)} /> 
      </Tabs>
    </AppBar>
    <Spinner left={70} top={0} className={classes.fabSpinner}  isLoading={isLoading} />
    <TabPanel value={value} index={0}>
    <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <Profile onChange={onChange} userDetails={userDetails} userSession={props.userSession}/>
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            xs={12}
          >
            <ProfileDetails userDetails={userDetails} userSession={props.userSession}/>
          </Grid>
          <Grid
            item
            lg={12}
            md={8}
            xs={12}
          ><ProfileDedicatedmail handleConfigureMail={handleConfigureMail} childParameters={childParameters}  userDetails={userDetails} dedicatedEmail={dedicatedEmail} userSession={props.userSession}/>
            
          </Grid>
        </Grid>
      </Container>
    </TabPanel>
    
    <TabPanel value={value} index={1}>
    {"Plan & Usage "}
    </TabPanel>   
    
    <TabPanel value={value} index={2}>
    <Container maxWidth="lg">
      <Grid
          container
          spacing={3}
        >
           
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <Password userSession={props.userSession} userDetails={userDetails}/>
          </Grid>
        
        </Grid>
        
      </Container>
    </TabPanel>        
  </div> 
  </Page>
    
  );
};

export default Account;
