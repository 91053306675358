import React,{useState,useEffect} from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import ListInvoices from './ListInvoice';
import Toolbar from './Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';




 
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  } 
}));

const CustInvoiceList = (props) => {
  const classes = useStyles();  
 
   return (
    <Page
      className={classes.root}
      title="Invoice"
    >
     
      <Container maxWidth={false}>
       <Box mt={3}>
      <ListInvoices {...props}/>
        </Box>
     

      </Container>
       
    </Page>
  );
};

export default CustInvoiceList;
