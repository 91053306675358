import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuIcon from '@material-ui/icons/MoreVert';
import {
  Folder as FolderIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  CheckBox as CheckBoxIcon
} from '@material-ui/icons';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  Modal,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Chip,
  MenuItem,
  Menu,

} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Amplify, { API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { useSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
Amplify.configure(awsconfig);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));
function BootstrapTooltip(props) {
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const BusinessPlaces = (props, { className, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [businessPlaces, setbusinessPlaces] = useState([]);
  const GSTINRegExp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  const [openModel, setOpenModel] = useState(false);
  const [isExitsGSTIN, setExitsGSTIN] = useState();
  const [modalStyle] = useState(getModalStyle);
  const [row, setRow] = useState({});
  const [updateBusinessPlc, setupdateBusinessPlc] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState("");
  const [updateBankDetail, updateBankDetails] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [featureOpen, setFeatureOpen] = useState(false);

  const [gstnEnable, setgstnEnable] = useState(false);


  const handleOpen = async (e, evntAction) => {

    if (evntAction === 'EditBank') {
      setgstnEnable(true);
      setOpenModel(true);
      featureModelOpen();

    }
    else if (evntAction === 'AddBank') {
      setRow([]);
      setgstnEnable(false);
      setOpenModel(true);
      await setMenuOpen(false);
      featureModelOpen();
    }
    else if (evntAction === 'DeleteBank') {

      DeleteBankDetails(row.gstin);
      await setMenuOpen(false);
    }

  };

  const featureModelOpen = (e, rowData) => {
    setFeatureOpen(true);
  };
  const featureModelClose = e => {
    setFeatureOpen(false);
  };

  const handleModelClose = () => {
    setOpenModel(false);
    featureModelClose();
  };
  async function handleMenuClose() {
    await setMenuOpen(false);
    // setFeatureOpen(false);
  }
  const handleModelOpen = (e, rowData) => {
    //checkAccessCode(e,rowData);      
    setDeleteOpen(true);
  };
  const handleFeatureOpen = e => {
    setFeatureOpen(true);
  };
  async function CheckingGSTIN(value) {

    console.log("Get GST No");
    let apiName = "OptiVIVeAPI";
    let path = '/b2cstore/AvailableGSTIN';
    var body = {};
    body.gstin = value;
    body.tenantId = props.userSession.TenantId
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    };
    console.log(myInit);
    API.post(apiName, path, myInit).then(response => {
      console.log("Responce Data GST ::", response);
      setExitsGSTIN(response.value);

    }).catch(err => {
      console.log(err);
    });
  }
  async function DeleteBankDetails(value) {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, TenantId: props.userSession.TenantId, pk: value },
        queryStringParameters: {
          module: "user",
          op: "deleteBankDetails"
        }
      };
      console.log(JSON.stringify(myInit.body));
      var response = await API.post(apiName, path, myInit);
      console.log("Deleted Confirm ::", response);
      getBankDetails();


    }
    catch (error) {
      console.log("set login time error", error);
    }
  }

  const getBankDetails = async () => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, TenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "user",
          op: "getBankDetails"
        }
      };
      var Bankresponse = await API.post(apiName, path, myInit);
      await updateBankDetails(Bankresponse);


    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
  const [values, setUserDetails] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    GSTIN: "",
    CompanyName: "",
    BusinessPlaces: ""
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setUserDetails(props.userDetails);
    setbusinessPlaces([]);
    setbusinessPlaces(businessPlaces => [...businessPlaces, props.userDetails.BusinessPlaces]);
    // console.log('businessPlaces :' + businessPlaces)
    if (props.childParameters == 1) {
      handleSaveClick();
    }
    getBankDetails();

  }, [props]);


  const handleClick = (row, action) => {
    handleClickOpen(row, action);
    setAction(action);
    featureModelClose();


  }
  const handleClickOpen = (row, action) => {
    setOpen(true);
    const BusPlaces = [];
    const BplacesArray = businessPlaces;
    if (action == 'update') {
      row.status = "inactive";
      setRow(row);
      var jsonData = JSON.stringify(row);
      console.log("Update ::", jsonData);
      // BplacesArray.map((gstValue) => (
      //   gstValue.filter(gt => gt != jsonData).map((gst) => (
      //     BusPlaces.push(gst)
      //   ))));
      // setupdateBusinessPlc(BusPlaces);

    } else if (action == 'activate') {
      row.status = "active";
      setRow(row);
      var jsonData = JSON.stringify(row);
      BplacesArray.map((gstValue) => (
        gstValue.filter(gt => gt != jsonData).map((gst) => (
          BusPlaces.push(gst)
        ))));
      setupdateBusinessPlc(BusPlaces);

    } else if (action == 'add') {
      BplacesArray.map((gstValue) => (
        gstValue.filter(gt => gt != jsonData).map((gst) => (
          BusPlaces.push(gst)
        ))));
      const AddBPlaces = {
        "gstin": row.gstin, "AccNum": row.AccNum, "BankName": row.BankName,
        "BankBranch": row.BankBranch, "ifsc": row.ifsc, "vpa": row.vpa, "primary": "no", "status": "active",
        "OnlinePayment": ""
      };
      console.log("Add ::", AddBPlaces);
      BusPlaces.push(AddBPlaces);

      setupdateBusinessPlc(row);// New change
      handleModelClose();
      getBankDetails();
    }
  };
  async function openMenu(event, gstVal) {
    let anchorElement = event.currentTarget;
    setgstnEnable(true);
    setRow(gstVal);
    if (anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true);


  }
  const handleClose = () => {
    row.status = "active";
    setRow(row);
    setOpen(false);
    featureModelClose();
  };
  const handleSaveClick = () => {
    //updatepropertyDB();   
    setOpen(false);
    setIsLoading(false);
  };
  const style = {
    float: 'right'
  };
  const DisplayingErrorMessagesSchema = Yup.object().shape({

    AccNum: Yup.number()
      .integer()
      .default(0)
      .min(2, 'Too Short!')
      .required('Required'),
    BankName: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    BankBranch: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    ifsc: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    vpa: Yup.string()
      .required('Required'),
    gstin: Yup.string().required("GSTIN is required").matches(GSTINRegExp, 'GSTIN is not valid').test('Unique GSTIN', 'GSTIN already in use',
      function (value) {
        return new Promise((resolve, reject) => {

          CheckingGSTIN(row.gstin);
          isExitsGSTIN == 'NotAvailable' ? resolve(false) : resolve(true)
        })
      }
    )
  });

  const FetaureModel = function (params) {
    return (
      <Dialog
        open={featureOpen}
        onClose={e => featureModelClose()}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent>
          <Formik
            initialValues={{
              gstin: row.gstin,
              AccNum: row.AccNum,
              BankName: row.BankName,
              BankBranch: row.BankBranch,
              ifsc: row.ifsc,
              vpa: row.vpa
            }}
            validationSchema={
              DisplayingErrorMessagesSchema

            }
            onSubmit={async (values) => {
              // console.log('ADD :: ',values);
              await handleClick(values, 'add');
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create Branch <Button color="primary" onClick={(event, row) => { handleModelClose() }} style={style} ><CloseIcon></CloseIcon></Button>
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {/* New GSTIN */}
                  </Typography>
                </Box>


                <TextField
                  error={Boolean(touched.gstin && errors.gstin)}
                  fullWidth
                  helperText={touched.gstin && errors.gstin}
                  label="GSTIN"
                  margin="normal"
                  name="gstin"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gstin}
                  variant="outlined"
                  disabled={gstnEnable}
                />
            Bank Details
                <TextField
                  error={Boolean(touched.AccNum && errors.AccNum)}
                  fullWidth
                  helperText={touched.AccNum && errors.AccNum}
                  label="Account Number"
                  margin="normal"
                  name="AccNum"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.AccNum}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.BankName && errors.BankName)}
                  fullWidth
                  helperText={touched.BankName && errors.BankName}
                  label="Bank Name"
                  margin="normal"
                  name="BankName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BankName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.BankBranch && errors.BankBranch)}
                  fullWidth
                  helperText={touched.BankBranch && errors.BankBranch}
                  label="Bank Branch"
                  margin="normal"
                  name="BankBranch"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BankBranch}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.ifsc && errors.ifsc)}
                  fullWidth
                  helperText={touched.ifsc && errors.ifsc}
                  label="IFSC"
                  margin="normal"
                  name="ifsc"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ifsc}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.vpa && errors.vpa)}
                  fullWidth
                  helperText={touched.vpa && errors.vpa}
                  label="Virtual Payment Address( VPA )"
                  margin="normal"
                  name="vpa"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vpa}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                   </Button>
                </Box>


              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  };


  return (

    <div className={classes.root} >
      <FetaureModel />
      <Card>

        <CardHeader
          subheader=""
          title="Business Places"
          action={
            
            <Tooltip title="Add">
              <Button
                variant="outlined"
                className="errorIcon"
                style={{ color: "blue" }}
                onClick={(event, row) => {
                  handleOpen(event, "AddBank")
                }}

              >
                Add
</Button></Tooltip>
          }
        />
      
        <Divider />
        {/* Start test */}

        <PerfectScrollbar>
          <Box minWidth={800}>
            <div className={classes.demo} width="100%">

              {/* className={isLoading ? 'parentDisable' : ''} */}
              {/* <div className='overlay-box'>      
        <Spinner left={70} top={0}  isLoading={isLoading} />
        </div> */}
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    GSTIN
                </TableCell>
                  <TableCell>
                    Account Number
                </TableCell>
                  <TableCell>
                    Bank Name
                </TableCell>
                  <TableCell>
                    Bank Branch
                </TableCell>
                  <TableCell>
                    IFSC
                </TableCell>
                  <TableCell>
                    VPA
                </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>


                {
                  updateBankDetail.data ?
                    updateBankDetail.data.map((gstValue) => (
                      <TableRow
                        hover
                        key={gstValue.gstin}
                      >
                        <TableCell>
                          {gstValue.gstin}
                        </TableCell>
                        <TableCell>
                          {gstValue.AccNum}
                        </TableCell>
                        <TableCell>
                          {gstValue.BankName}
                        </TableCell>
                        <TableCell>
                          {gstValue.BankBranch}
                        </TableCell>
                        <TableCell>
                          {gstValue.ifsc}
                        </TableCell>
                        <TableCell>
                          {gstValue.vpa}
                        </TableCell>
                        {/* <TableCell>
                            <BootstrapTooltip
                              title={gstValue.status === "inactive" && gstValue.Message ? gstValue.Message : ""}
                            >
                              <Chip
                                classes={{ label: classes.statusPrimary, root: gstValue.status && gstValue.status === 'active' ? classes.greenStatus : classes.greyStatus }}
                                label={gstValue.status ? gstValue.status : "inactive"}
                                size="small"
                              />
                            </BootstrapTooltip>
                          </TableCell> */}
                        <TableCell>
                          {/* {(gstValue.primary == 'Yes') ? <Button color="primary">
                          </Button> : */}
                          {<Button
                            onClick={(event, row) => {
                              openMenu(event, gstValue);
                            }}
                            color="primary"
                            endIcon={<MenuIcon />}
                            size="large"
                            variant="text"
                          >
                          </Button>
                          }
                          {/* } */}
                        </TableCell>
                      </TableRow>
                    ))
                    : <ListItem></ListItem>

                }
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={menuAnchor}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={(event, row) => {
            handleOpen(event, "EditBank")
          }}>
            Edit Bank Details
            </MenuItem>

          {(row.primary == 'Yes') ?
            <MenuItem onClick={handleOpen}>
              Default Bank Details
            </MenuItem> :
            <MenuItem onClick={(event, row) => {
              handleOpen(event, "DeleteBank")
            }}>
              Delete Bank
          </MenuItem>
          }
        </Menu>

        {/*END test */}
         

      </Card>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Notification"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to {action} GSTIN Details
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
          </Button>
            <Button /*onClick={handleSaveClick}*/
              onClick={props.handleGSTIN.bind(null, updateBusinessPlc)}
              color="primary" autoFocus>
              Yes
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

BusinessPlaces.propTypes = {
  className: PropTypes.string,
  handleGSTIN: PropTypes.func
};

export default BusinessPlaces;
