import React, { useState,useEffect }  from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {RemoveRedEye,GetApp} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/MoreVert';
import ReactJson from 'react-json-view'
import Moment from 'react-moment'; 
import Toolbar from './Toolbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  IconButton
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MaterialTable from "material-table";
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
import Spinner from 'src/common/Spinner';
import CloseIcon from '@material-ui/icons/Close';
const options = [
    'View Invoice',
    'Download PDF',
    'Download Json' 
  ];
  
const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  toolbarCss:{
    'margin-bottom':'15px'
  }
}));

const ListInvoices = (props,{ className, ...rest }) => {
  console.log("props",props);
  const classes = useStyles(); 
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false); 
  const [pageSize, setPageSize] = usePersistedState('pageSize', 5); 
  const [currentRow, setCurrentRow] = useState({}); 
  const [menuAnchor, setMenuAnchor] = useState(null); 
  const [menuOpen, setMenuOpen] = useState(false); 
  const [invoicefilter, setInvoicefilter] = useState("");
  const [invoicejson, setInvoicejson] = useState({});
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [queryData, setqueryData] = useState("");
  const tableRef = React.createRef();
  
  const [invoiceHistoryData, setInvoiceHistoryData] = useState({});
  const [invoiceHistory, setinvoiceHistory] = useState(false);
  const handleClickListItem = (event) => {      
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = (e) => {
   
      setInvoiceOpen(true); 
  };
  const handleClose1 = (e) => {
    setInvoiceOpen(false);
  };
  const DialogTitle =  (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const iconChange = (index) => {
    
    const iconCls=(index===0)?<RemoveRedEye fontSize="small" />:<GetApp fontSize="small" />;
     return iconCls;
  };
  const handleClickHistoryOpen = (e) => {

    setinvoiceHistory(true);
  };
  const handleHistoryClose = (e) => {
    setinvoiceHistory(false);
  };
  
  async function GetInvoiceJson() { 
    let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Signed Invoice";
    apiModule = "invoice";
    op = "getInvoiceJson";
    body = {
      type: masterType,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  }
  async function  viewJson() {
    var row = currentRow; 
    var invJson=await GetInvoiceJson();
    await setInvoicejson(invJson);
    handleClickOpen();
 }
  async function GetPagingData(query) {
    setqueryData(query);
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
     let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Scanned Today";
    apiModule = "invoice";
    op = "listRecentInvoices";
    body = {
      type: masterType,      
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId,
      Filter: invoicefilter,
      pageSize: pageSize,
      pageNo: pageNo,
      status:'Waiting'
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        setBlankSpace(response.total,pageSize)
         resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  const setBlankSpace = (tol,pageSize) => {
     
    if(pageSize != 5 && pageSize > tol ){
      if(tol>5)
      {
      setPageSize(tol);
      }
      else
      setPageSize(5);
    }
      
  };
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  }
 
 async function handleMenuClose(){
  await setMenuOpen(false);
 }
 async function viewHTML() {
  var row = currentRow;
  var invJson = await GetInvoiceHtml();

  //await setInvoicejson(invJson);
  //const htmlContent = `<p><a href="http://jsdf.co">&hearts; nice job!</a></p>`;
  await setInvoicejson(invJson);
  handleClickOpen();
}
async function viewInvoiceHistory() {
  var row = currentRow;
  var invHistory = await GetInvoiceHistoryView(
    {
      pageSize: 5,
      page: 0
    }
  );
  console.log("Invoice Activity Data", invHistory.data);
  //await setInvoicejson(invJson); 
  await setInvoiceHistoryData(invHistory);
  handleClickHistoryOpen();
}
async function GetInvoiceHtml() {
  let body = {};
  var masterType = "",
    apiModule = "",
    op = "";
  setIsLoading(true);
  setPageSize(pageSize);
  masterType = "Signed Invoice";
  apiModule = "invoice";
  op = "GetInvoiceView";
  body = {
    type: masterType,
    pk: currentRow.pk,
    UserID: props.userSession.UserName,
    tenantId: props.userSession.TenantId

  };
  body.userData = props.userSession;
  return new Promise((resolve, reject) => {
    let apiName = "OptiVIVeAPI";
    let path = "/b2c ";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: apiModule,
        op: op
      }
    };
    API.post(apiName, path, myInit).then(response => {
      setIsLoading(false);
      resolve(response);
    }).catch(err => {
      console.log(err); reject(err);
    });
  });
}
async function GetInvoiceHistoryView(query) {
  console.log("Invoice Curr Row :: ", currentRow);
  let body = {};
  var pageSize = query.pageSize;
  var pageNo = (query.page + 1);
  var masterType = "",
    apiModule = "",
    op = "";
  setIsLoading(true);
  setPageSize(pageSize);
  masterType = "Signed Invoice";
  apiModule = "invoice";
  op = "GetInvoiceHistoryView";
  body = {
    type: masterType,
    pk: currentRow.pk,
    InvoiceNum: currentRow.InvoiceNum,
    UserID: props.userSession.UserName,
    tenantId: props.userSession.TenantId,
    pageSize: pageSize,
    pageNo: pageNo,
  };
  body.userData = props.userSession;
  return new Promise((resolve, reject) => {
    let apiName = "OptiVIVeAPI";
    let path = "/b2c ";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: apiModule,
        op: op
      }
    };
    API.post(apiName, path, myInit).then(response => {
      setIsLoading(false);
      resolve(response);
    }).catch(err => {
      console.log(err); reject(err);
    });
  });
}
  function BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }
  
  async function handleSearch(e) {
    let target=e.target;    
    if(e!=undefined){
     console.log(target.value);
     setInvoicefilter(target.value);      
    // GetPagingData(queryData);
    tableRef.current.onQueryChange({page: 0, Filter: target.value})

     
     //props.filter=target.value;
      }  
  }
  return (
  <>
    <Dialog open={invoiceOpen} onClose={e => handleClose1()} fullWidth={true}
        maxWidth={"md"} aria-labelledby="form-dialog-title">
        <DialogTitle id="email-dialog-title" onClose={e => handleClose1()}>
          Invoice View
    </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: invoicejson }} />
        </DialogContent>
      </Dialog>
      <Dialog open={invoiceHistory} onClose={e => handleHistoryClose()} fullWidth={true}
        maxWidth={"md"} aria-labelledby="form-dialog-title">
        <DialogTitle id="email-dialog-title" onClose={e => handleHistoryClose()}>
          Invoice History View
       </DialogTitle>
        <DialogContent>
          <PerfectScrollbar>
            <Box minWidth={800}>
              <div className={isLoading ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                  <Spinner left={70} top={0} isLoading={isLoading} />
                </div>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Invoice No
                </TableCell>

                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Invoice Date
                    </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      Amount
                </TableCell>
                    <TableCell>
                      Status
                </TableCell>
                    <TableCell>
                      Activity Time
                </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceHistoryData.data ? invoiceHistoryData.data.map((invoice) => (
                    <TableRow
                      hover
                      key={invoice.pk}
                    >
                      <TableCell>
                        {invoice.InvoiceNum}
                      </TableCell>

                      <TableCell>
                        {invoice.InvoiceDate}
                      </TableCell>

                      <TableCell>
                        {invoice.InvAmount}
                      </TableCell>
                      <TableCell>
                        <BootstrapTooltip
                          title={invoice.status === "Rejected" && invoice.Message ? invoice.Message : ""}
                        >
                          <Chip
                            color="primary"
                            label={invoice.status ? invoice.status : "Validated"}
                            size="small"
                          />
                        </BootstrapTooltip>
                      </TableCell>
                      <TableCell>
                        {invoice.ct}
                      </TableCell>
                    </TableRow>
                  ))
                    : <ListItem></ListItem>}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </DialogContent>
      </Dialog>
     
      <Toolbar className={classes.toolbarCss} handleSearch={handleSearch}/>  
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Invoice Generated Today" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>

          <MaterialTable
            tableRef={tableRef}
            
            columns={[
              {
                title: 'Invoice No',
                field: 'InvoiceNum',
                searchable: false,
                width:"5%"
              },
              {
                title: 'Customer Name',
                field: 'CustomerName',
                searchable: false ,
                width:"15%"
              },
              {
                title: 'Invoice Date',
                field: 'InvoiceDate',
                searchable: false,
                width:"5%"
              },
              {
                title: 'Scanned On',
                field: 'ct',
                searchable: false,
                render: rowData => <Moment format="DD/MM/YYYY">{rowData.ProcessedDate}</Moment>,
                width:"5%"
              },
              {
                title: 'Amount',
                field: 'InvAmount',
                searchable: false,
                width:"5%"
              } ,
              {
                title: 'Status',
                field: 'status',
                searchable: true,
                width:"5%"
              } 
            ]}
           
            data={async query =>
              new Promise((resolve, reject) => {
                GetPagingData(query).then(result => {
                  resolve({
                    data: result.data,
                    page: result.page,
                    totalCount: result.total
                  });
                });
              })
            }
            options={{
              searchFieldStyle: {
                display: 'none'
              },
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              toolbar: false,
              pageSize: pageSize,
              pageSizeOptions: [5, 25, 50, 100, 200]
            }}
            actions={[
              {
                icon: ()=> <MenuIcon/>,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, row) => {
                  openMenu(event, row);
                }
              }
           ]}
          />
         
        </Box>
      </PerfectScrollbar>
      <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
           <MenuItem onClick={viewHTML}>View Invoice</MenuItem>
     
     <MenuItem onClick={viewInvoiceHistory}>
       Invoice scan/payment History
         </MenuItem>
          </Menu>
    </Card>
  </>);
};

ListInvoices.propTypes = {
  className: PropTypes.string
};

export default ListInvoices;
