import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,CardActions,FormControl,InputLabel,Input,FormHelperText,
  InputAdornment,
  IconButton,
  Paper,OutlinedInput
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WarningIcon from '@material-ui/icons/Warning'; 
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
const useStyles = makeStyles(({
  root: {width:800},
  child_root: {width:780,'padding-top':'5px'},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
  sc:{
    fontSize: 16,
    'margin-top':'10px',
    textAlign:"start"
  },
  paper: {
    
    textAlign: 'center',
    fontSize: 14,
    color:'gray',
    marginBottom:20,
    display:'flex'

  } 
}));

const Inbound = (props,{className, ...rest }) => {
  const classes = useStyles();
  const [tenantKeys,setTenantKeys] =useState([]);
  const [selected,setSelected] =useState("");
  console.log("props.tenantKeys",props.tenantKeys);
  useEffect(() => {
    setTenantKeys(props.tenantKeys);
   }, [props]); 
  const [values, setValues] = React.useState({    
    InboundProduction2: false,
    InboundProduction1: false,
    InboundSandbox1: false,
    InboundSandbox2: false,
  });
  let myInput = null; 
  const handleClickCopyKey=(e,name)=>{
    console.log(e,values);
    setSelected(name);
    if(e==="InboundProduction2"){
      setValues({ ...values, InboundProduction2: !values.InboundProduction2 });
    }
    if(e==="InboundProduction1"){
      setValues({ ...values, InboundProduction1: !values.InboundProduction1 });
    }
    if(e==="InboundSandbox1"){
      setValues({ ...values, InboundSandbox1: !values.InboundSandbox1 });
    }
    if(e==="InboundSandbox2"){
      setValues({ ...values, InboundSandbox2: !values.InboundSandbox2 });
    }
    myInput.select();
    document.execCommand("copy");
    alert("Copied the text: " + myInput.value);
    
  }
  const handleClickShowPassword = (e) => {
    console.log(e,values);
    if(e==="InboundProduction2"){
      setValues({ ...values, InboundProduction2: !values.InboundProduction2 });
    }
    if(e==="InboundProduction1"){
      setValues({ ...values, InboundProduction1: !values.InboundProduction1 });
    }
    if(e==="InboundSandbox1"){
      setValues({ ...values, InboundSandbox1: !values.InboundSandbox1 });
    }
    if(e==="InboundSandbox2"){
      setValues({ ...values, InboundSandbox2: !values.InboundSandbox2 });
    }
    
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form
    className={clsx(classes.root, className)}
    {...rest}
  >
      <input hidden value={selected} ref={(ref) => myInput = ref} />
    <Card className={classes.root}>
    <CardContent>
    
      <CardActions>
      <Grid
          container
          spacing={3}
        >
      <Grid item xs={8}>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
      Inbound API Keys   
        </Typography>       
        </Grid> 
        <Grid item xs={4}>    
        <Button size="small">Learn More in the docs<NavigateNextIcon/></Button>
        </Grid>
        </Grid>
      </CardActions> 
      <Divider/>
      <Grid
          container
          spacing={3}
        >
    {/*        <Grid item xs={12}>
      <Typography className={classes.sc} color="textSecondary" gutterBottom>
        Secrets
      </Typography>
      </Grid> */}
      <Grid item xs={12}>
          <Paper className={classes.paper}>
          <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"

        >
          <Grid item xs={1}>
           <WarningIcon style={{ fontSize: 20 }}/>
           </Grid>
           <Grid item xs={11}>
           <Typography className={classes.sc} color="textSecondary" gutterBottom>
           Never Share your secret keys in plaintext with anyone,Including paid employees.If a secret key is compromised,please rotate it.
           </Typography>
           </Grid>
           </Grid>
          </Paper>
        </Grid>
          {tenantKeys && tenantKeys.length > 0 && tenantKeys.map((Keys,index) => {
            console.log("tenantKeys #####",values["showProd"+(index+1)]);  
            return Keys.APIType === "Production" && Keys.type==="Inbound API Key" ? 
            (
              <Grid key={index} item xs={12}>
              <FormControl fullWidth className={classes.child_root} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Production API Key</InputLabel>
                <OutlinedInput
                  id="ProductionKey"
                  type={values[Keys.Name] ? 'text' : 'password'}
                  value={Keys.pk} 
                  endAdornment={
                    <InputAdornment position="end">
                       <Tooltip title="Add">
                <IconButton
                  aria-label="toggle password visibility"
                 onClick={()=>{handleClickShowPassword("Add")}}
                  onMouseDown={handleMouseDownPassword}
                >
                  <AddCircleIcon fontSize="small"/>
                   
                </IconButton></Tooltip>
                      <Tooltip title="View">
                        <IconButton
                          aria-label="toggle password visibility"
                        onClick={()=>{handleClickShowPassword(Keys.Name)}}
                         onMouseDown={handleMouseDownPassword}
                        >
                          <Visibility fontSize="small" /> 
                        </IconButton></Tooltip>
                      <Tooltip title="Copy">
                        <IconButton
                          aria-label="toggle password visibility"
                        onClick={()=>{handleClickCopyKey(Keys.Name,Keys.pk)}}
                         onMouseDown={handleMouseDownPassword}
                        >
                          <FileCopyOutlinedIcon fontSize="small" />
                        </IconButton></Tooltip>
                      <Tooltip title="Rotate">
                        <IconButton
                          aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                         onMouseDown={handleMouseDownPassword}
                        >
                          <RotateLeftIcon fontSize="small" /> 
                        </IconButton></Tooltip> 
                        <Tooltip title="Delete">
                <IconButton
                  aria-label="toggle password visibility"
                 onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  <DeleteIcon fontSize="small"/>
                   
                </IconButton></Tooltip>
                    </InputAdornment>
                  }
                  labelWidth={140}
                />
              </FormControl>
              </Grid>
            ):Keys.APIType === "Sandbox" && Keys.type==="Inbound API Key" ?
            (<Grid key={index} item xs={12}><FormControl fullWidth className={classes.child_root} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Sandbox API Key</InputLabel>
            <OutlinedInput
              id="sandboxKey"
              type={values[Keys.Name] ? 'text' : 'password'}
              value={Keys.pk}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="View">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { handleClickShowPassword(Keys.Name) }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      <Visibility fontSize="small" />

                    </IconButton></Tooltip>
                  <Tooltip title="Copy">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=>{handleClickCopyKey(Keys.Name,Keys.pk)}}
                      onMouseDown={handleMouseDownPassword}
                    >
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton></Tooltip>
                  <Tooltip title="Rotate">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      <RotateLeftIcon fontSize="small" />

                    </IconButton></Tooltip>

                </InputAdornment>
              }
              labelWidth={125}
            />
          </FormControl></Grid>):null
          })} 
        </Grid>     
    </CardContent>    
  </Card>
  </form>
  );
};

Inbound.propTypes = {
  className: PropTypes.string,
 };

export default Inbound;
