import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {RemoveRedEye,GetApp} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/MoreVert';
import ReactJson from 'react-json-view'
import Moment from 'react-moment'; 
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  IconButton,
  Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MaterialTable from "material-table";
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
import Spinner from 'src/common/Spinner';
import CloseIcon from '@material-ui/icons/Close'; 
import CustomizedTooltips from 'src/common/tooltIp';
import Toolbar from './Toolbar';
import {  useLocation} from 'react-router-dom';

const options = [
    'View Invoice',
    'Download PDF',
    'Download Json' 
  ];
  
const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  tooltipText: {
    wordWrap:"nowrap",
    float:"left",
    paddingRight: "9px",
    verticalAlign: "middle",
    display: "inline-flex",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.78)",
    fontSize: "0.875rem"
    },
    toolbarCss:{
      'margin-bottom':'15px'
    }
}));

const ListInvoices = (props,{ className, ...rest }) => {
  let location = useLocation();

  console.log("props",props); 
  //console.log("customer",location.state.customer.Vendor); 
  const classes = useStyles(); 
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false); 
  const [pageSize, setPageSize] = usePersistedState('pageSize', 5); 
  const [currentRow, setCurrentRow] = useState({}); 
  const [menuAnchor, setMenuAnchor] = useState(null); 
  const [menuOpen, setMenuOpen] = useState(false); 
  const [invoicefilter, setInvoicefilter] = useState(location.state.customer.Vendor);
  const [invoicejson, setInvoicejson] = useState({});
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [queryData, setqueryData] = useState("");
  const tableRef = React.createRef();
  const handleClickListItem = (event) => {      
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = (e) => {
   
      setInvoiceOpen(true); 
  };
  const handleClose1 = (e) => {
    setInvoiceOpen(false);
  };
 
  const DialogTitle =  (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const iconChange = (index) => {
    
    const iconCls=(index===0)?<RemoveRedEye fontSize="small" />:<GetApp fontSize="small" />;
     return iconCls;
  };
  
  
  async function GetInvoiceJson() { 
    let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Signed Invoice";
    apiModule = "invoice";
    op = "getInvoiceJson";
    body = {
      type: masterType,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  }
  async function  viewJson() {
    var row = currentRow; 
    var invJson=await GetInvoiceJson();
    await setInvoicejson(invJson);
    handleClickOpen();
 }
  async function GetPagingData(query) {
    setqueryData(query);
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Signed Invoice";
    apiModule = "invoice";
    op = "listRecentInvoices";
    body = {
      type: masterType,      
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId,
      Filter: invoicefilter,
      pageSize: pageSize,
      pageNo: pageNo 
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  }
 
 async function handleMenuClose(){
  await setMenuOpen(false);
 }
 async function downloadInvoice(type) { 
     let body = {}; 
    let apiName = "OptiVIVeAPI";
    let path = "/b2c ";
    let masterType = "Signed Invoice";
    body = {
      type: type,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body:body,
      queryStringParameters: {
        module:"invoice",
        op: "downloadInvoice"
      }
    };
    await API.post(apiName, path, myInit).then(response => { 
      if(response.success){
      const link = document.createElement('a');
      link.href = response.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      
    }).catch(err => {
      console.log(err);
    }); 
  }

  function BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    const classes = useStylesBootstrap();
    return  <Tooltip arrow classes={classes} {...props} />;
  }
  async function handleSearch(e) {
    let target=e.target;    
    if(e!=undefined){
     console.log(target.value);
   //  setInvoicefilter(target.value);      
     
    //tableRef.current.onQueryChange({page: 0, Filter: target.value})

     
     //props.filter=target.value;
      }  
  }
  const createHeaderWithTooltip = (title, tooltip) => {
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
        > <Grid item>
                <Typography className={classes.tooltipText}>{title}</Typography>
            </Grid>
            <Grid >
                <CustomizedTooltips content={tooltip} />
            </Grid>
        </Grid>
    );
  };
  return (
  <>
    <Dialog open={invoiceOpen} onClose={e => handleClose1()} fullWidth={true}
        maxWidth={"md"} aria-labelledby="form-dialog-title">
        <DialogTitle   id="email-dialog-title" onClose={e => handleClose1()}>
          JSON View
        </DialogTitle>
        <DialogContent>
        <ReactJson src={invoicejson} enableClipboard={true} />
        </DialogContent>
      </Dialog>
      <Toolbar className={classes.toolbarCss} handleSearch={handleSearch}/>  
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="E-Invoice" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>

          <MaterialTable
            tableRef={tableRef}
            columns={[
              {
                title: 'Invoice No',
                field: 'InvoiceNo',
                searchable: false,
                width:"5%",
                render: rowData => { return rowData.Message?createHeaderWithTooltip(rowData.InvoiceNo,rowData.Message):rowData.InvoiceNo  }
              },
              {
                title: 'Vendor',
                field: 'SellerName',
                searchable: false ,
                width:"15%"
              },
              {
                title: 'Invoice Date',
                field: 'InvoiceDate',
                searchable: false,
                width:"5%"
              },
              {
                title: 'Received On',
                field: 'ProcessedDate',
                searchable: false,
                render: rowData => <Moment format="DD/MM/YYYY">{rowData.TransactionDate}</Moment>,
                width:"5%"
              },
              {
                title: 'Source',
                field: 'source',
                searchable: false,
                width:"5%"
              } 
            ]}
           
            data={async query =>
              new Promise((resolve, reject) => {
                GetPagingData(query).then(result => {
                  resolve({
                    data: result.data,
                    page: result.page,
                    totalCount: result.total
                  });
                });
              })
            }
            options={{
              searchFieldStyle: {
                display: 'none'
              },
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              toolbar: false,
              pageSize: pageSize,
              pageSizeOptions: [5, 25, 50, 100, 200]
            }}
            actions={[
              {
                icon: ()=> <MenuIcon/>,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, row) => {
                  openMenu(event, row);
                }
              }
           ]}
          />
         
        </Box>
      </PerfectScrollbar>
      <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={viewJson}>View Invoice Json</MenuItem>
            <MenuItem onClick={()=>downloadInvoice("json")}>
              Download Invoice Json 
            </MenuItem>
            <MenuItem onClick={()=> downloadInvoice("pdf")}>
              Download Invoice PDF 
            </MenuItem>
          </Menu>
    </Card>
  </>);
};

ListInvoices.propTypes = {
  className: PropTypes.string
};

export default ListInvoices;
