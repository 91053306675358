import React, { useState,useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
import Amplify, { API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import PropTypes from 'prop-types'; 
import {Tabs,Tab,AppBar} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import Spinner from 'src/common/Spinner';
import BusinessPlaces from './BusinessPlaces';
import Devices from './Devices';
import AccessCode from './AccessCode';
import Inbound from './Inbound';
import usePersistedStateString from 'src/utils/usePersistedStateString'
Amplify.configure(awsconfig);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  fabSpinner: {     
    display: 'inline-block',
    position: 'relative',     
    marginLeft: '50%'
     
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}
const SettingsView = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = usePersistedStateString('value',0,'number');
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [childParameters,setchildParameters]=useState("");
  const [tenantKeys,setTenantKeys] =useState([]);

  const [bankDetail, setBankDetail] = useState({});
  const [DefaultBankData, setDefaultBankDatas] = useState({});
  const handleChange = (event, newValue) => {     
    setTabValue(newValue);    
  };
  useEffect(() => {     
   const waitfun= async ()=>{
    await setIsLoading(true);
    await getUserAPIKey();
    await getUser();
    await setIsLoading(false);
    await getDefaultBankDetails();
   // await getBankDetails();
   // console.log("SettingsView tenantKeys",tenantKeys);
   }
    waitfun();
    },[]); 
 
   const getUser = async () => { 
      try {
       let apiName = "OptiVIVeAPI";
       let path = "/b2c ";
       let myInit = {
         headers: { "Content-Type": "application/json" },
         body: { masterType: "User", UserID: props.userSession.UserName,TenantId:props.userSession.TenantId },
         queryStringParameters: {
           module: "user",
           op: "getUserProfile"
         }
       };
        var response=await API.post(apiName, path, myInit);
       // await setUserDetails(response.data); 
        setUserDetails(prvCard => { return response.data });
        //console.log("User Details RES",JSON.stringify(response.data)); 
        // console.log("User Details ",JSON.stringify(userDetails)); 
     }
     catch (error) {
       console.log("set login time error",error); 
     } 
   } 
   const getDefaultBankDetails = async () => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, TenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "user",
          op: "getDefaultBankDetails"
        }
      };
      var Bankresponse = await API.post(apiName, path, myInit);
      console.log("Default Bank Details1 ::", (Bankresponse));
      var dataVal=[];
      dataVal = Bankresponse.data;
      console.log("Default Bank Details2 ::", (dataVal[0]));
      await setDefaultBankDatas(dataVal[0]);
      console.log("Default Bank Details3 ::", DefaultBankData);
      // setRow(setDefaultBankData[0]);
      // console.log("Default Bank Details3 ::", (row));
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
  

   const getUserAPIKey = async () => { 
    try {
     let apiName = "OptiVIVeAPI";
     let path = "/b2c ";
     let myInit = {
       headers: { "Content-Type": "application/json" },
       body: { masterType: "User", UserID: props.userSession.UserName,TenantId:props.userSession.TenantId },
       queryStringParameters: {
         module: "user",
         op: "getUserAPIKey"
       }
     };
      var response=await API.post(apiName, path, myInit);
      await setTenantKeys(response.data);  
   }
   catch (error) {
     console.log("set login time error",error); 
   } 
 } 
 const handleGSTIN = (b, evt, key, payload) => { 
    console.log('Bussiness',b);
    updateGstininDB(b);
}
async function updateGstininDB(b) { 
  console.log("UPDATE PROPERTY TO DB");
  let apiName = "OptiVIVeAPI";
  let path = "/b2c ";     
  var body = {}; 
  body.masterType = "User";
  body.userData = props.userSession;
  body.BusinessPlaces =b;     
  body.tenantId=props.userSession.TenantId 
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body: body,   
    queryStringParameters: {
      module: "user",
      op: "setbusinessPlaces"
    }
  };
  console.log(myInit);

  API.post(apiName, path, myInit).then(response => {
    console.log("UPDATE USER PROPERTY", response);
    //setIsLoading(false);
    getUser();
    setchildParameters(1);
    if(response.success)
    {
      enqueueSnackbar("Business place registered successfully.. !! .",{ 
        variant: 'success',
      }); 
    }
    else
    {
      enqueueSnackbar("Something went wrong. Try after sometime!!",{ 
        variant: 'error',
      }); 
    }
  }).catch(err => {
    console.log(err);
    //setIsLoading(false); 
    setchildParameters(1);
    
  });
} 
  return (
    <Page
      className={classes.root}
      title="Settings"
    ><div>    
    <AppBar position="static" style={{ background: 'white' }}>
    <Tabs value={tabValue} onChange={handleChange} aria-label="Profile tabs">
      <Tab style={{ color: '#2E3B55' }} label="Business Places" {...a11yProps(0)} />
      <Tab style={{ color: '#2E3B55' }} label="Payments" {...a11yProps(1)} />
      <Tab style={{ color: '#2E3B55' }} label="API Keys" {...a11yProps(2)} />
      {/* <Tab style={{ color: '#2E3B55' }} label="Outbound API" {...a11yProps(3)} />
      <Tab style={{ color: '#2E3B55' }} label="Inbound API" {...a11yProps(4)} />
      <Tab style={{ color: '#2E3B55' }} label="SFTP" {...a11yProps(5)} /> */}
      </Tabs>
    </AppBar>
    <Spinner left={70} top={0} className={classes.fabSpinner}  isLoading={isLoading} />
    <TabPanel value={tabValue} index={0}>
    <Container maxWidth="lx">
        <Grid
          container
          spacing={3}
        >
           
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <BusinessPlaces handleGSTIN={handleGSTIN} bankDetail={bankDetail} childParameters={childParameters} userDetails={userDetails} userSession={props.userSession}/>
          </Grid>
          
        </Grid>
      </Container>    </TabPanel>
      <TabPanel value={tabValue} index={1}>
     <Devices DefaultBankData={DefaultBankData} userDetails={userDetails} userSession={props.userSession}/>
    </TabPanel>
    <TabPanel value={tabValue} index={2}>
     <AccessCode userDetails={userDetails} userSession={props.userSession}/>
    </TabPanel> 
    <TabPanel value={tabValue} index={3}>
    {"Outbound API"}
    </TabPanel> 
    <TabPanel value={tabValue} index={4}>
    <Inbound tenantKeys={tenantKeys} userDetails={userDetails} userSession={props.userSession}/>
    </TabPanel> 
    <TabPanel value={tabValue} index={5}>
    {"SFTP"}
    </TabPanel> 
  </div> 
      
    </Page>
  );
};

export default SettingsView;
