import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = (props, { className, ...rest }) => {
  const classes = useStyles();
  console.log("props.userDetails", props.userDetails);
  const [values, setValues] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    GSTIN: "",
    CompanyName: ""
  });
  const [isExitsGSTIN, setExitsGSTIN] = useState();
  const GSTINRegExp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  const DisplayingErrorMessagesSchema = Yup.object().shape({

    // firstName: Yup.number()
    //   .required('Required'),
    //   lastName: Yup.string()
    //   .required('Required'),
    //   email: Yup.string()
    //   .min(2, 'Too Short!')
    //   .max(100, 'Too Long!')
    //   .required('Required'),
    //   phone: Yup.string()
    //   .integer()
    //   .required('Required'),
    //   company: Yup.string()
    //   .required('Required'),
    //   gstin: Yup.string().required("GSTIN is required").matches(GSTINRegExp, 'GSTIN is not valid').test('Unique GSTIN', 'GSTIN already in use',
    //   function (value) {
    //     return new Promise((resolve, reject) => {

    //       //CheckingGSTIN(values.gstin);
    //       isExitsGSTIN == 'NotAvailable' ? resolve(false) : resolve(true)
    //     })
    //   }
    // )
  });
  const handleClick = (row, action) => {

  }
  console.log("User Details values", values);
  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  useEffect(() => {
    setValues(props.userDetails);
  }, [props]);

  return (

    <Formik
      initialValues={{
        firstName: values.FirstName,
        lastName: values.LastName,
        email: values.Email,
        phonenumber: values.PhoneNumber,
        gstin: values.GSTIN,
        company: values.CompanyName
        
      }}
      validationSchema={
        DisplayingErrorMessagesSchema

      }
      onSubmit={async (values) => {
        // console.log('ADD :: ',values);
      //  await handleClick(values, 'add');
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          // autoComplete="off"
          // noValidate
          // className={clsx(classes.root, className)}
          // {...rest}
        >
          {/* <Card>
            <CardHeader
              subheader="The information can be edited"
              title="Profile"
            />
            <Divider />
            <CardContent> */}
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                   
                  <TextField
                    fullWidth
                    //helperText="Please specify the first name"
                    label="First name"
                    margin="normal"
                    name="firstName"
                   // onChange={handleChange}
                    required
                    value={values.FirstName }
                    variant="outlined"
                    // defaultValue="First name"

                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Last name"
                    margin="normal"
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={values.LastName}
                    variant="outlined"
                   // defaultValue="Disabled"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    required
                    value={values.Email}
                    variant="outlined"
                   // defaultValue="Disabled"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phonenumber"
                    onChange={handleChange}
                    type="number"
                    value={values.PhoneNumber}
                    variant="outlined"
                  //  defaultValue="Disabled"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="GSTIN"
                    name="gstin"
                    onChange={handleChange}
                    required
                    value={values.GSTIN}
                    variant="outlined"
                   // defaultValue="Disabled"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Company Name"
                    name="company"
                    onChange={handleChange}
                    required
                    value={values.CompanyName}
                    variant="outlined"
                   // defaultValue="Disabled"
                  />

                </Grid>
              </Grid>
            {/* </CardContent>
            <Divider />
          </Card> */}
        </form>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
