import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout'; 
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView'; 
import RegisterView from 'src/views/auth/RegisterView';
import VerifyView from 'src/views/auth/VerifyView';
import SettingsView from 'src/views/settings/SettingsView';
import InvoiceView from 'src/views/invoice/InvoiceListView';
import CustomerInvoiceView from 'src/views/customerInvoice/CustomerInvoiceView';
import WaitingInvoice from 'src/views/waitingInvoice/waitingInvoiceListView';
import FailedInvoice from 'src/views/failedInvoice/failedInvoiceListView';
import ValidatedInvoice from 'src/views/validatedInvoice/validatedInvoiceListView'; 
import AllInvoiceView from 'src/views/Allinvoice/AllListView';
 
const routes =(isAuthenticated,userHasAuthenticated,userSession,setuserSession,props)=>{ 
  console.log("userSession.AccountType",userSession.AccountType);
  return [ 
  {
    path: 'app',
    element: isAuthenticated && userSession.AccountType!=="ADMIN_MASTER"?<DashboardLayout isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} userSession={userSession} setuserSession={setuserSession} props={props} />:<Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView userSession={userSession} /> },
      { path: 'vendor', element: <CustomerListView userSession={userSession} /> },
      { path: 'dashboard', element: <DashboardView userSession={userSession}/> },
      { path: 'invoice', element: <InvoiceView userSession={userSession} /> },
      { path: 'AllInvoice', element: <AllInvoiceView userSession={userSession} /> },      
      { path: 'waitingInvoice', element: <WaitingInvoice userSession={userSession} /> },
      { path: 'failedInvoice', element: <FailedInvoice userSession={userSession} /> },
      { path: 'validatedInvoice', element: <ValidatedInvoice userSession={userSession} /> },
      { path: 'settings', element: <SettingsView userSession={userSession} /> }, 
      { path: 'cutomerInvoice', element: <CustomerInvoiceView userSession={userSession} /> },       
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout   />,
    children: [
      { path: 'login', element: <LoginView isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} userSession={userSession} setuserSession={setuserSession} props={props}/> },
      { path: 'register', element: <RegisterView userSession={userSession}/> },
      { path: 'verify', element: <VerifyView userSession={userSession}/> },
      { path: '404', element: <NotFoundView userSession={userSession}/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> }, 
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]};

export default routes;
