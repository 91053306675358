import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import trafficjsonData from "./traffic.json";

import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import DoneIcon from '@material-ui/icons/Done';
import DataUsageIcon from '@material-ui/icons/DataUsage';
const useStyles = makeStyles(() => ({
  root: {
    height: '20%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  }
}));

const MonthlyUsageChart = (props,{ className, ...rest }) => {
  const classes = useStyles();
  console.log("props inv download",props.matrix);
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
            THIS MONTH USAGE
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
             {props.matrix["Monthly Count"]}/{trafficjsonData.StartUp.InboundLimit}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Avatar className={classes.avatar}>
              <DataUsageIcon />
            </Avatar>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

MonthlyUsageChart.propTypes = {
  className: PropTypes.string
};

export default MonthlyUsageChart;
