/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:28cb51b8-a831-4d98-ab22-93c9b165eb3b",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_25vmiMmIG",
    "aws_user_pools_web_client_id": "4sau470de0pbve8vmp93gf1cch",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "OptiVIVeAPI",
            "endpoint": "https://zm8o03jtul.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-south-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "b2ceinvoice-dev",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "optivive-store125556-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
