import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close'; 
import Spinner from 'src/common/Spinner'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
Amplify.configure(awsconfig);
const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDedicatedmail = (props, { className, ...rest }) => {
  var expr = /^[a-zA-Z0-9._]*$/
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isAvailable, setIsAvailable] = useState(false);
  const [open, setOpen] = useState(false);
  //ConfiguredEmail
  const [values, setValues] = useState({
    ConfiguredEmail: ""
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const [isMailExits, setMailExits] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userMailDetails, setUserMailDetails] = useState({});
  const [formData, setForm] = useState({});
  const [dedicatedEmail,setDedicatedEmail]=useState("");
  useEffect(() => {
    setValues(props.userDetails);
    if(props.childParameters==1){
      handleClose();
    }
    values.ConfiguredEmail && values.ConfiguredEmail != '' ? setMailExits(true) : setMailExits(false);
    setDedicatedEmail(props.dedicatedEmail?props.dedicatedEmail:"");
    setMailExits(window.$Email)
  }, [props]);

  useEffect(() => {
    console.log("userMailDetails", userMailDetails);
    if (userMailDetails) {
      isMailavailable(userMailDetails)
    }
  }, [userMailDetails]);
  async function updateMail(e) {
    setIsLoading(true);
    setForm(e);
    handleClickOpen(e);
  }
  


  const isMailavailable = async (e) => {
    if (e == 'Available') {
      await setIsAvailable(true);
    } else {
      await setIsAvailable(false);
    }
  }

  const handleKeyPress = async (event) => {
    var processMail = event+"@" + dedicatedEmail;
    await getMailUser(processMail);
  };

  const getMailUser = async (e) => {
    setIsLoading(true);
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", ConfiguredEmail: e, tenantId:props.userSession.TenantId  },
        queryStringParameters: {
          module: "user",
          op: "getUserMail"
        }
      };
      var response = await API.post(apiName, path, myInit);
      await setUserMailDetails(response.value);
      await setIsLoading(false);
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log('close');
    setOpen(false);
    setIsLoading(false);
  };
   
  return (<>
    <Spinner left={70} top={0} isLoading={isLoading} />
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={
        Yup.object().shape({
          ConfiguredEmail: Yup.string().max(255).matches(expr).required('Dedicated mail is required'),

        })
      }
      onSubmit={async (values) => {
        await updateMail(values);
        console.log("(JSON.stringify(values, null, 2)", (JSON.stringify(values, null, 2)));

      }}

    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
             
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={isMailExits ? isMailExits : isSubmitting}
                type="submit"
              >
                Save
          </Button>
            </Box>
          </Card>
        </form>

      )}
    </Formik>
  </>);
};

ProfileDedicatedmail.propTypes = {
  className: PropTypes.string,
  isMailExits: PropTypes.bool,
  handleConfigureMail:PropTypes.func
};

export default ProfileDedicatedmail;
